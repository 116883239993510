const initialState = {
    menu:{},
    isLoadingMenu:false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_MENU_BY_BUSINESS_PENDING': {
            return {
                ...state,
                isLoadingMenu: true,
            }
        }
        case 'GET_MENU_BY_BUSINESS_FULFILLED': {
            return {
                ...state,
                isLoadingMenu: false,
                menu: action.payload.data,
            }
        }
        default:
            return state;
    }
}

export default reducer;