import spring from "../../axios/axios"

const APIGETBYBUSINESS = "getMenuByBusiness"

export const getMenuByBusiness = data => {
    return async dispatch => {
        return dispatch({
            type: 'GET_MENU_BY_BUSINESS',
            payload: spring.get(`${APIGETBYBUSINESS}/${data}`)
        })
    }
}


