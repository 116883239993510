import React, { Component } from 'react';
import { connect } from "react-redux";
import { getMenuByBusiness } from "../redux/actions/menuActions";
import { GrMenu, GrClose } from "react-icons/gr";
import { ChevronForwardSharp } from "react-ionicons-v5";
import { Element, animateScroll as scroll, scroller } from 'react-scroll'
import { server } from "../config/server";
import { useHistory } from "react-router-dom";
import Pietanza from './Pietanza';


class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            pietanza: null,
            openPietanza: false,
        }
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        if (id) {
            this.props.getMenuByBusiness(id);
        }
    }

    toggleMenu = () => {
        this.setState({
            open: !this.state.open
        }, () => {
            if (this.state.open) {
                document.getElementsByTagName("body")[0].style.overflowY = "hidden"
            } else {
                document.getElementsByTagName("body")[0].style.overflowY = "auto"
            }
        })
    }

    goToTipo = id => {
        this.toggleMenu();
        document.getElementsByTagName("body")[0].style.overflowY = "auto"
        scroller.scrollTo(id, {
            duration: 1200,
            delay: 0,
            smooth: 'easeInOutQuart',
            offset: -20
        })
    }

    goToPietanza = pietanza =>{
        this.setState({pietanza:pietanza, openPietanza:true})
        document.getElementsByTagName("body")[0].style.overflowY = "hidden"
        document.getElementById("open-menu").classList.add("menu-opened")
    }

    goBack = () =>{
        this.setState({openPietanza:false})
        setTimeout(() => {
            this.setState({pietanza:null})
        }, 1000);
        document.getElementsByTagName("body")[0].style.overflowY = "auto"
        document.getElementById("open-menu").classList.remove("menu-opened")
    }

    render() {

        const { menu, isLoadingMenu } = this.props;
        const { open, pietanza, openPietanza } = this.state;
        let note = ""

        return (
            <>
                <Pietanza pietanza={pietanza} isOpen={openPietanza} goBack={this.goBack}/>
            <div className="qrmenu">
                <div className={`slide-menu ${open ? "opened" : ""}`}>
                    <div className="close-menu" onClick={this.toggleMenu}>
                        <GrClose />
                    </div>
                    {
                        menu.response ?

                            menu.response.pietanze.length === 0 ?
                                <div>Niente da mostrare</div>
                                :
                                <ul>
                                    {
                                        menu.response.pietanze.filter(tipo => tipo.id < 1).map((tipologia, index) => {
                                            return (
                                                <li key={index} onClick={() => this.goToTipo(tipologia.id)}>{tipologia.descrizione}</li>
                                            )
                                        })
                                    }
                                </ul>
                            :
                            <></>
                    }
                </div>
                <div className="open-menu" id="open-menu" onClick={this.toggleMenu}>
                    <GrMenu />
                </div>
                {
                    menu.response &&
                    <>
                        {
                            !menu.response.business.extension ?
                                <div className="business">
                                    <h3>{menu.response.business.descrizione}<br></br><div>MENU</div></h3>
                                </div>
                                :
                                <div className="business-image"
                                    style={{
                                        backgroundImage: `url(${server}downloadBusinessImage?param=${menu.response.business.id}.${menu.response.business.extension}#${new Date().getTime()})`
                                    }}>
                                </div>
                        }
                        {
                            menu.response.pietanze.map((pietanza, index) => {
                                if (pietanza.id < 1) {
                                    note = pietanza.tipologia.note
                                }
                                return (
                                    <div key={index}>
                                        {
                                            pietanza.id < 1 ?
                                                <div className="tipologia">
                                                    <Element name={pietanza.id + ""}>
                                                        <h2>{pietanza.descrizione}</h2>
                                                        <div className="sep"></div>
                                                    </Element>
                                                </div>
                                                :
                                                <div className={`pietanza ${pietanza.extension ? "has-image" : ""}`} 
                                                onClick={(pietanza.extension || pietanza.info) ? () => this.goToPietanza(pietanza) : undefined}>
                                                    {
                                                        (pietanza.extension || pietanza.info) &&
                                                        <div className="goTo"><ChevronForwardSharp/></div>
                                                    }
                                                    <div className="titolo">
                                                        <h3>{pietanza.descrizione}</h3>
                                                        <h3 className="prezzo">{pietanza.prezzo.toFixed(2)}<span>&nbsp;&euro;</span></h3>
                                                    </div>
                                                    <div className="materie_prime">
                                                        {
                                                            pietanza.materieprime.map((mp, index) => {
                                                                return (
                                                                    <span key={index}>{mp.descrizione}{index < pietanza.materieprime.length - 1 ? " / " : ""}</span>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                        }
                                        {
                                            (index < menu.response.pietanze.length - 1 &&
                                                menu.response.pietanze[index + 1].id < 0 && note !== null) ||
                                                (index === menu.response.pietanze.length - 1 && menu.response.pietanze[index].id > 0 && note !== null) ?
                                                <div className="note" dangerouslySetInnerHTML={{ __html: note.replace(/\n\r?/g, '<br/>') }} />
                                                :
                                                <></>
                                        }
                                    </div>
                                )
                            })
                        }
                    </>
                }
            </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        menu: state.menu.menu,
        isLoadingMenu: state.menu.menu
    }
}

export default connect(mapStateToProps, { getMenuByBusiness })(Home);