import React from 'react'
import {server} from "../config/server";
import {FaArrowLeft} from 'react-icons/fa'

const Pietanza = ({pietanza, goBack, isOpen}) => {
    return (
        <div className={`pietanza--page ${isOpen ? "isOpen" : ""}`}>
            <div className={`go--back ${isOpen ? "isOpen" : ""}`} onClick={goBack}>
                <FaArrowLeft/>
            </div>
            
            {
                pietanza &&
                <>
                    {
                        pietanza.extension &&

                        <div className="pietanza--image">
                            <img src={`${server}downloadPietanzaImage?param=${pietanza.id}.${pietanza.extension}#${new Date().getTime()}`} alt="Image"/>
                        </div>
                    }
                    
                    <div className="pietanza--content">
                        <div className="pietanza--title">
                            {pietanza.descrizione}
                        </div>
                        <div className="materie_prime">
                            {
                                pietanza.materieprime.map((mp, index) => {
                                    return (
                                        <span key={index}>{mp.descrizione}{index < pietanza.materieprime.length - 1 ? " / " : ""}</span>
                                    )
                                })
                            }
                        </div>
                        <div className="pietanza--prezzo">{pietanza.prezzo.toFixed(2)}<span>&nbsp;&euro;</span></div>
                        {
                            (pietanza.info && pietanza.info !== "") &&

                            <div className="pietanza--info">
                                <div>Descrizione</div>
                                {pietanza.info}
                            </div>
                        }
                    </div>
                </>
            }
        </div>
        
    )
}

export default Pietanza
